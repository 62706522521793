/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				if(window.location.pathname == "/rmseguros/quienes-somos/") {
					$('#nav-header').addClass('nav-no-home');
					$('#primary-menu').addClass('ul-no-home');
					$('#img-navbar').attr('src', window.location.origin + '/rmseguros/wp-content/themes/sage/dist/images/rm_logocolores.svg');
				}
				$(document).ready(function() {
					$('.close-navigation').on('click', function(e) {
						e.preventDefault();
					});
					var width = window.innerWidth;
					if(width <= 991) {
						$('.social-icon').addClass('hide');
					} else {
						$('.social-icon').removeClass('hide');
					}

					$(window).on('resize', function(event) {
						var width = window.innerWidth;
						if(width <= 991) {
							$('.social-icon').addClass('hide');
						} else {
							$('.social-icon').removeClass('hide');
						}
					});

					$('#closeNavigation').hide();

					$('#openNavigation').on('click', function(e) {
						e.preventDefault();
						$('#navContainer')
							.fadeIn(500)
							.addClass('open-navigation');

						$('#navContainer ul li a').fadeIn(250);

						$('.social-icon')
							.removeClass('hide')
							.addClass('show');

						$('#closeNavigation').css('display', 'block');
					});

					$('#closeNavigation').on('click', function(e) {
						e.preventDefault();
						$('.social-icon')
							.removeClass('show')
							.addClass('hide');
						$('#navContainer')
							.fadeOut(500)
							.removeClass('open-navigation');
						$('#navContainer ul li a').fadeOut(250);
						setTimeout(function() {
							$('#closeNavigation')
								.fadeOut(250)
								.hide();
						}, 100);
					});

					$('#quote-container, .quote-ctn, .quote-container').on('inview', function(event, isInView) {
						if (isInView) {
							$(this).addClass('in');
						} else {
							$(this).removeClass('in');
						}
					});

					var heightContainer = $('#about-container.about-container').innerHeight();
					$('.slides-description').height(heightContainer);
					$('.slides-description, .slides-about').height(heightContainer);

					$('.description-hex-container span a').on('click', function(e) {
						e.preventDefault();
						var lines = document.querySelectorAll('.vertical-line');
						var line = document.querySelector('.vertical-line');
						var dataDescription = $(this).data('description');
						var currentDescription = $('.rmcultura-description[data-description='+dataDescription+']');
						var description = document.querySelectorAll('.rmcultura-description');

						var heightCulturaDescription = currentDescription.height() + 280;

						$('.rmcultura-description, .rmcultura-description[data-description='+dataDescription+']').addClass('hide-element');

						hasClass = line.classList.contains('increment-height');

						if(hasClass) {
							description.forEach(function(currentElement, index, listObj) {
								hasHideClass = currentElement.classList.contains('hide-element');
								if(hasHideClass) {
									currentElement.classList.add('hide-element');
									currentElement.setAttribute('style', 'display:none');
								}
							});

							currentDescription.fadeIn(500, function() {
								currentDescription
									.css({'display': 'block'})
									.removeClass('hide-element');
							});

							$('.vertical-line').height(heightCulturaDescription);
						} else {
							lines.forEach(function(currentElement, index, listObj) {
								var hasIncrementClass = currentElement.classList.add('increment-height');
							});
							$('.vertical-line').height(heightCulturaDescription);
							setTimeout(
								function() {
									currentDescription.fadeIn(500, function() {
										currentDescription.removeClass('hide-element');
									});
								}
							,500);
						}
					});
					//Inicio código para los col-lg de los share
					if ($(window).width()>=992) {

			        	if(!$(".rrssItem").hasClass('col-lg-1')){
			        		$(".rrssItem").addClass('col-lg-1');
			        		$(".rrssItemPint").addClass('col-lg-1');
			        	}

					}else if($(window).width()>=576){
	
			        	if($(".rrssItem").hasClass('col-lg-1')){
			        		$(".rrssItem").removeClass('col-lg-1');
			        		$(".rrssItemPint").removeClass('col-lg-1');
			        	}

			    	}else if($(window).width()>=215){
			        	if($(".rrssItem").hasClass('col-lg-1')){
			        		$(".rrssItem").removeClass('col-lg-1');
			        		$(".rrssItemPint").removeClass('col-lg-1');
			        	}			    		
			    	}

			    	$(window).resize(function() {
			    		if ($(window).width()>=992) {
				        	if(!$(".rrssItem").hasClass('col-lg-1')){

				        		$(".rrssItem").addClass('col-lg-1');
				        		$(".rrssItemPint").addClass('col-lg-1');
			        		}
			        	}
						else if($(window).width()>=576){
		
				        	if($(".rrssItem").hasClass('col-lg-1')){
				        		$(".rrssItem").removeClass('col-lg-1');
				        		$(".rrssItemPint").removeClass('col-lg-1');
				        	}

				    	}else if($(window).width()>=215){
				        	if($(".rrssItem").hasClass('col-lg-1')){
				        		$(".rrssItem").removeClass('col-lg-1');
				        		$(".rrssItemPint").removeClass('col-lg-1');
				        	}
			        	}

			    	});
					//Fin código para los col-lg de los share
					// Inicio código search

					    $('.search-img').click( function() {
					    	if ($(window).width()>=992) {

								var toggleIMG    ='';       
						        var toggleBorder ='';    
						        var toggleWidth  ='';     
						        var toggleHeight ='';    


						        var toggleIMG     = $(".search-field").width()  == 0 ? window.location.origin+"/wp-content/themes/sage/assets/images/equis-04.svg" : window.location.origin+"/wp-content/themes/sage/assets/images/lupa-04.svg";
						        //var toggleBorder    = $(".search-field").width()  == 0 ? "solid #6598CC 1px" : "0";
						        //var toggleWidth     = $(".search-field").width()  == 0 ? "89vw" : "0";
						        //var toggleHeight    = $(".search-field").height() == 0 ? "50px" : "0";

						        $(".search-field").width()  == 0 ? $('.search-field').addClass('active-search-field') : $('.search-field').removeClass('active-search-field');
						        /*$('.search-field').animate({ width: toggleWidth, height: toggleHeight});
						        $('.search-field').css('border',toggleBorder);*/

						        $('.search-img').attr('src',toggleIMG);					    		
					    	}else if($(window).width()>=576){
								var toggleIMG ='';       
						        var toggleBorder ='';    
						        var toggleWidth='';     
						        var toggleHeight='';    


						        var toggleIMG       = $(".search-field").width()  == 0 ? window.location.origin+"/wp-content/themes/sage/assets/images/equis-04.svg" : window.location.origin+"/wp-content/themes/sage/assets/images/lupa-04.svg";
						        //var toggleBorder    = $(".search-field").width()  == 0 ? "solid #6598CC 1px" : "0";
						        //var toggleWidth     = $(".search-field").width()  == 0 ? "80vw" : "0";
						        //var toggleHeight    = $(".search-field").height() == 0 ? "50px" : "0";

						        $(".search-field").width()  == 0 ? $('.search-field').addClass('active-search-field') : $('.search-field').removeClass('active-search-field');
						        //$('.search-field').animate({ width: toggleWidth, height: toggleHeight});
						        $('.search-field').css('border',toggleBorder);

						        $('.search-img').attr('src',toggleIMG);
					    	}else if($(window).width()>=280){
								var toggleIMG ='';       
						        var toggleBorder ='';    
						        var toggleWidth='';     
						        var toggleHeight='';    


						        var toggleIMG       = $(".search-field").width()  == 0 ? window.location.origin+"/wp-content/themes/sage/assets/images/equis-04.svg" : window.location.origin+"/wp-content/themes/sage/assets/images/lupa-04.svg";
						        //var toggleBorder    = $(".search-field").width()  == 0 ? "solid #6598CC 1px" : "0";
						        //var toggleWidth     = $(".search-field").width()  == 0 ? "65vw" : "0";
						        //var toggleHeight    = $(".search-field").height() == 0 ? "50px" : "0";

						        $(".search-field").width()  == 0 ? $('.search-field').addClass('active-search-field') : $('.search-field').removeClass('active-search-field');
						        //$('.search-field').animate({ width: toggleWidth, height: toggleHeight});
						        //$('.search-field').css('border',toggleBorder);

						        $('.search-img').attr('src',toggleIMG);
					    	}
    					});
					// Fin código search
					// Inicio de código blog owl-carousel
						$('#slides-container').owlCarousel({
							nav: true,
							navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
							responsive:{
								992: {
									items:4
								},
								576: {
									items:2
								},
								310: {
									items:1
								}
							},
						});

					// Fin de código owl-carousel
					// Inicio de código blog owl-carousel
						$('#related-container').owlCarousel({
							nav: true,
							navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
							responsive:{
								992: {
									items:4
								},
								576: {
									items:2
								},
								310: {
									items:1
								}
							},
						});

					// Fin de código owl-carousel
					
					// Inicio de codigo para enviar cambiar de tipo de fomulario
						$('.form-select--view').change(function() {
							var changeSelectForm = $(this).val();

							 if (changeSelectForm == 'info_form') {
								$('.attachBtn').hide();
								$('.subjectContact, .subjectContactLabel').show();
								$('.comentLabel').html('Comentario*');
								
							}
							else{
								$('.attachBtn').show();
								$('.subjectContact, .subjectContactLabel').hide();
								$('.comentLabel').html('¿Por qué te gustaría trabajar con nosotros?*');
							}

							
							// if ($( "select option:selected" ) == 'Reclutamiento'){
							// 	$('.attachBtn').show();
							// 	$('.subjectContact').hide();
							// }
						});

					// fin de codigo para enviar cambiar de tipo de fomulario
					if ($(window).width()>992) {
						var mapWidth = $('.info-contact-container').width()-30;
							$('#map').css('width', mapWidth);

						$(window).resize(function() {
							var mapWidth = $('.info-contact-container').width()-30;
							$('#map').css('width', mapWidth);
						});
						}
					else{
						var mapWidth = $('.info-contact-container').width()-15;
							$('#map').css('width', mapWidth);

						$(window).resize(function() {
							var mapWidth = $('.info-contact-container').width()-15;
							$('#map').css('width', mapWidth);
						});
					}

					// Inicio de codigo para calcular el width del mapa

					// Fin de codigo para calcular el width del mapa

					// Inicio de codigo para enviar datos por el formulario de contacto
					var fileExtension = "";
					var file = ""
					$('#cvContactFile:file').change(function() {
						file = $("#cvContactFile")[0].files[0];			      	        
									      	        
										      });
					$('#sendForm').click(function(e) {
					       e.preventDefault();
					      var typeContact = $('.form-select--view').val();
					      var subjectContact = $('input[name=subjectContact]').val();
					      var nameContact = $('input[name=nameContact]').val();
					      var mailContact = $('input[name=mailContact]').val();
					      var phoneContact = $('input[name=phoneContact]').val();
					      var menssageContact = $('textarea[name=menssageContact]').val();
					   	  var cvContact_file = $('#cvContactFile:file');
					      var formData = new FormData();
					      formData.append('cvContact',file);
					      formData.append('typeContact', typeContact);
					      formData.append('subjectContact',subjectContact);
					      formData.append('nameContact',nameContact);
					      formData.append('mailContact',mailContact);
					      formData.append('phoneContact',phoneContact);
					      formData.append('menssageContact',menssageContact);
					      formData.append('action','ajax_registMail');

					                $.ajax({
					                        url: 'http://localhost:3000/rmseguros/wp-admin/admin-ajax.php',
					                        type: 'POST',
					                        dataType: 'JSON',
					                        data: formData,
					                        cache: false,
            								contentType: false,
            								processData: false,
					                })
					                .done(function(data) {
					                    $('.done_msg').show();

					                    setTimeout(function(){
					                        $('.done_msg').hide();
					                    }, 3000);
					                })
					                .fail(function() {
					                    $('.fail_msg').show();

					                    setTimeout(function(){
					                        $('.fail_msg').hide();
					                    }, 3000);
					                })
					                .always(function() {
					                        console.log("always");
					                });
					            });
					// Fin de codigo para enviar datos por el formulario de contacto

					// Inicio de codigo para el newsletter

					function setCookie(cname, cvalue, exdays) {
					    var d = new Date();
					    d.setTime(d.getTime() + (exdays*24*60*60*1000));
					    var expires = "expires="+ d.toUTCString();
					    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
					}

					if(document.cookie){
					}else{						
						setTimeout(function(){ 
							//$('#rm-newsletter').addClass('show'); 

							$('#rm-newsletter').modal('show');
							if($('#rm-newsletter').hasClass('show')){

							}  
						}, 3000);
						setCookie();						
					}
						

					// Fin de codigo para el newsletter
					//Código redirección para soluciones
					$('.solution').on('click',function(){
						var soluciones = $(this).attr('aria-labelledby');		
						var url = $(this).parent().attr('data-url')							

						$('#aria-label').attr('value', soluciones);
						console.log($('#aria-label').attr('value'));
						setTimeout(function(){
							$('#solutions_form').submit();
						},500);
						//$('#solutions_form').submit();
						
						
					});

					// Inicio de codigo para mostrar la descripcions de las mascotas de Quienes Somos
						$('#pet .pet-description').first().addClass('active');

						$( "#pet .pet-description" ).each(function() {
							if($(this).hasClass('active')){
								$(this).addClass('show');
							};
						});

					// Fin de codigo para mostrar la descripcions de las mascotas de Quienes Somos

					// Inicio de codigo para calcular la altura de los items de industria	
						var altFinal = 0;
						$('.stats-block').each(function() {
							var altActual = $(this).height();
							// if(altFinal > altActual){
							// 	altFinal = altFinal;
							// }
							// else{
							// 	altFinal = altActual;
							// }
						});

					// Fin de codigo para calcular la altura de los items de industria	
					//Active accordion quienes somos

						 $(".card").on("show.bs.collapse hide.bs.collapse", function(e) {
						    if(e.type=="show"){
						      $(this).find('.box_accordion .box_text').addClass('activeFont');
						    }else{
						      $(this).find('.box_accordion .box_text').removeClass('activeFont');
						    }						    
						}); 

					//fin active accordion quienes somos
					//imagenes a svg
						jQuery('img.svg,img.svg2').each(function(){
						     var $img = jQuery(this);
						     var imgID = $img.attr('id');
						     var imgClass = $img.attr('class');
						     var imgURL = $img.attr('src');

						     jQuery.get(imgURL, function(data) {
						       // Get the SVG tag, ignore the rest
						       var $svg = jQuery(data).find('svg');
						       // Add replaced image's ID to the new SVG
						       if(typeof imgID !== 'undefined') {
						         $svg = $svg.attr('id', imgID);
						       }
						       // Add replaced image's classes to the new SVG
						       if(typeof imgClass !== 'undefined') {
						         $svg = $svg.attr('class', imgClass+' replaced-svg');
						       }
						       // Remove any invalid XML tags as per http://validator.w3.org
						       $svg = $svg.removeAttr('xmlns:a');
						       // Check if the viewport is set, else we gonna set it if we can.
						       if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
						         $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
						       }
						       // Replace image with new SVG
						       $img.replaceWith($svg);
						     }, 'xml');
	   					});
					//
					//active iconos
/*						$('a.pet-select--btn').on('click',function(){
								var icon_hover = $(this).attr('data-active');
							$('a.pet-select--btn').each(function(){
								var icon       = $(this).attr('data-normal');
								$(this).find('.pet-icon-wrap img').attr('src','');
								$(this).find('.pet-icon-wrap img').attr('src',icon);
							});
							$(this).find('.pet-icon-wrap img').attr('src',icon_hover);
						});*/
					//
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
